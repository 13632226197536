$fullWidth: calc(100% - 40px);
$backgroundColor: #666666;
$backgroundColor: #393E41;
$darkModeBackground: #150811;
$red: #f03434;
$blue: #74b9ff;
$purple: #ab9df2;
$white: #fff6f0;
$shadowColor: #15081144;

body {
    display: flex;

    background-color: $backgroundColor;

    margin: 0;

    width: 100%;

    font-family: Helvetica, Verdana, Geneva, Tahoma, sans-serif;

    &.dark-mode {
        background-color: $darkModeBackground;
    }
}

#root {
    width: 100%;
}

.homepage {
    display: grid;

    width: 100%;

    justify-items: center;
}

header {
    display: flex;

    background-color: $red;
    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;;

    padding: 20px;

    width: $fullWidth;
    max-height: 200px;

    &.dark-mode {
        background-color: $purple;
    }
}

main {
    display: grid;
    padding: 20px 40px 20px 40px;

    color: $darkModeBackground;

    max-width: 1400px;

    &.dark-mode {
        background-color: $white;
    }
}

.name {
    background-color: $red;
    
    border-radius: 10px;
    padding: 15px;

    box-shadow: 3px 3px 3px $shadowColor;

    height: fit-content;
}

.header {
    background-color: $blue;
    
    border-radius: 10px;
    padding: 15px;

    box-shadow: 3px 3px 3px $shadowColor;
}

.card {
    display: grid;

    background-color: $red;
    color: $white;
    
    border-radius: 10px;
    padding: 20px;

    box-shadow: 3px 3px 3px $shadowColor;

    height: 440px;
    width: 240px;

    a {
        color: $white;
    }
}

.info-card {
    h1, h2 {
        text-align: right;
        margin: 0;
        height: fit-content;
        height: -moz-max-content;
        height: -moz-fit-content;
        margin-top: 10px;
    }

    img {
        margin-left: auto;
        margin-right: auto;
        height: 150px;
    }
    
    dl {
        text-align: right;
    }

    address {
        text-align: right;
    }

    a {
        text-decoration: none;
    }
}

.project-card {
    img, .card-image {
        margin-left: auto;
        margin-right: auto;
        height: 250px;
        max-width: 200px;
    }
}

.card-group {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.project-section {
    padding: 20px;
}

.center {
    justify-self: center;
}

.info-section {
    display: grid;
    
    grid-template-columns: 1fr;

    padding: 20px;
}

.coming-section {
    img {
        height: 280px;
        justify-self: center;
    }
}

.intro {
    margin: 20px;
    font-size: 24px;

    background-color: $white;
    
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    box-shadow: 3px 3px 3px $shadowColor;

    border-left: 15px solid $red;
    padding-left: 5px;
    padding-right: 5px;
}

.links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

a {
    color: $darkModeBackground;

    font-size: 18px;
    &:hover {
        font-weight: 700;
    }
}

@media (min-width: 350px) {
    .card {
        width: 338px;
        height: 440px;
    }

    .project-card {
        img, .card-image {
            margin-left: auto;
            margin-right: auto;
            height: 250px;
            max-width: 325px;
        }
    }

    .coming-section {
        img {
            height: 300px;
            justify-self: center;
        }
    }
}

@media (min-width: 850px) {
    .card-group {
        grid-template-columns: repeat(2, 1fr);
    }

    .intro {
        grid-column: 1;
    }

    .info-card {
        grid-column: 3;
    }
}

@media (min-width: 1250px) {
    .card-group {
        grid-template-columns: repeat(3, 1fr);
    }
}
